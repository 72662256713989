<template>
  <div>
    <validation-observer ref="appraisalRules">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Vehicle Appraisal & Lien</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row>
        <b-col cols="12" md="7">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Vehicle Title Type" rules="required">
              <label for="vehicle-type" class="border-bottom">Vehicle Title Type* </label>
              <b-form-radio-group class="border-bottom pb-1">
                <b-form-radio v-model="tradeData.vehicleTitleType" name="vehicle-type" value="1">
                  Clean Owned
                </b-form-radio>
                <b-form-radio v-model="tradeData.vehicleTitleType" name="vehicle-type" value="2">
                  Owned with Lien Payout
                </b-form-radio>
                <b-form-radio v-model="tradeData.vehicleTitleType" name="vehicle-type" value="3">
                  Leased
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group>
            <label for="appraisedVehiclePrice">Appraised Vehicle Price*</label>

            <validation-provider #default="{ errors }" name="Appraised Vehicle Price" rules="required">
              <cleave id="appraisedVehiclePrice" v-model="tradeData.appraisedVehiclePrice" class="form-control" :raw="true" :options="options.number" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <label for="appraisal-notes">Appraisal Notes</label>
          <b-form-textarea id="appraisal-notes" v-model="tradeData.appraisalNotes" rows="3" />
        </b-col>
      </b-row>

      <b-row v-show="tradeData.vehicleTitleType == 2 || tradeData.vehicleTitleType == 3">
        <table class="table mt-1">
          <thead>
            <tr>
              <th scope="col">Lien Payout</th>
            </tr>
          </thead>
        </table>

        <b-col cols="12" md="7">
          <b-form-group>
            <label for="payout-type" class="border-bottom">Payout Type </label>
            <b-form-radio-group class="pb-1 border-bottom">
              <b-form-radio name="payout-type" v-model="tradeData.payoutType" :disabled="tradeData.vehicleTitleType == 3" class="mr-2" value="1">
                Finance Payout
              </b-form-radio>
              <b-form-radio name="payout-type" v-model="tradeData.payoutType" :disabled="tradeData.vehicleTitleType == 2" class="mr-2" value="2">
                Lease Payout
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="beforeTax">Net Payout Before Tax</label>
            <cleave id="beforeTax" placeholder="Option Price" v-model="tradeData.beforeTax" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="unitlDate">Good Until Date</label>
            <flat-pickr id="quotationDate" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="tradeData.unitlDate" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-if="tradeData.vehicleTitleType == 3">
          <b-form-group>
            <label for="gstRate">G.S.T Rate</label>
            <cleave id="gstRate" placeholder="Option Price" v-model="tradeData.gstRate" class="form-control" :raw="true" :options="options.percent" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-if="tradeData.vehicleTitleType == 3">
          <b-form-group>
            <label for="gstAmount">G.S.T Amount</label>
            <cleave id="gstAmount" disabled placeholder="Option Price" v-model="gstAmountValue" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" v-if="tradeData.vehicleTitleType == 3">
          <b-form-group>
            <label for="totalAmount">Total Payout Amount</label>
            <cleave id="totalAmount" disabled placeholder="Option Price" v-model="totalAmaountValue" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'cleave.js/dist/addons/cleave-phone.us';
import { required, email } from '@validations';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,

    required,

    VueNumericInput,
    ToastificationContent,
    Cleave,
    flatPickr,

    //validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    tradeData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      gstAmountValue: 0,
      totalAmaountValue: 0,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'tradeData.vehicleTitleType': {
      handler: function(id, before) {
        this.tradeData.payoutType = null;
        if (this.tradeData.vehicleTitleType == 2) {
          this.tradeData.payoutType = 1;
        } else if (this.tradeData.vehicleTitleType == 3) {
          this.tradeData.payoutType = 2;
        } else {
          this.tradeData.payoutType = null;
          this.tradeData.beforeTax = null;
          this.tradeData.unitlDate = null;
          this.tradeData.gstRate = null;
        }
      },
    },

    'tradeData.appraisedVehiclePrice': {
      handler: function(id, before) {
        this.tradeData.creditDue = this.tradeData.appraisedVehiclePrice;
      },
    },
    'tradeData.beforeTax': {
      handler: function(id, before) {
        if (this.tradeData.gstRate != null || this.tradeData.gstRate != '') {
          this.gstAmountValue = (this.tradeData.beforeTax / 100) * this.tradeData.gstRate;
          this.totalAmaountValue = Number((this.tradeData.beforeTax / 100) * this.tradeData.gstRate) + Number(this.tradeData.beforeTax);
          this.tradeData.totalLienPayout = this.totalAmaountValue;

          
        }
      },
    },

    'tradeData.gstRate': {

      handler: function(id, before) {
        if (this.tradeData.beforeTax != null || this.tradeData.beforeTax != '') {
          this.gstAmountValue = (this.tradeData.beforeTax / 100) * this.tradeData.gstRate;
          this.totalAmaountValue = Number((this.tradeData.beforeTax / 100) * this.tradeData.gstRate) + Number(this.tradeData.beforeTax);
          this.tradeData.totalLienPayout = this.totalAmaountValue;
          
        }
      },
    },

  },

  computed: {},

  methods: {},
};
</script>
