<template>
  <div>
    <b-row>
      <table class="table table-sm ">
        <thead>
          <tr>
            <th scope="col" class="table-primary">Price Calculation</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="some-radios" class="border-bottom">Contract Type </label>
          <b-form-radio-group stacked class="border-bottom pb-1">
            <b-form-radio v-model="saleQuoteData.priceContractType" name="contract-type" class="mt-2" value="1">
              Cash
            </b-form-radio>
            <b-form-radio v-model="saleQuoteData.priceContractType" name="contract-type" class="mt-2" value="2">
              Finance
            </b-form-radio>
            <!-- <b-form-radio v-model="saleQuoteData.priceContractType" name="contract-type" class="mt-2" value="3">
              Lease
            </b-form-radio> -->
            <span> </span>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <label for="some-radios" class="border-bottom">Sale Type </label>
          <b-form-radio-group stacked class="border-bottom pb-1">
            <b-form-radio v-model="saleQuoteData.saleType" name="sale-type" class="mt-2" value="Retail">
              Retail
            </b-form-radio>
            <b-form-radio v-model="saleQuoteData.saleType" name="sale-type" class="mt-2" value="Wholesale">
              Wholesale
            </b-form-radio>
            <!-- <b-form-radio v-model="saleQuoteData.priceContractType" name="contract-type" class="mt-2" value="3">
              Lease
            </b-form-radio> -->
            <span> </span>
          </b-form-radio-group>
        </b-form-group>


      </b-col>

      <b-col cols="12" sm="6" md="8" xl="9">
        <table class="table border table-sm">
          <tr>
            <th scope="col" class="text-right">Vehicle Listing Price</th>
            <td scope="col" v-if="saleQuoteData.listingPrice != null">{{ formatPrice(saleQuoteData.listingPrice) }}</td>
            <td scope="col" colspan="2" v-else>{{ formatPrice(0) }}</td>
          </tr>

          <tr>
            <td scope="col" style="width: 40%;" class="text-right">
              Discount Rate <span class="text-danger" v-if="userData.role != 'supermanager'">(Max: {{ userData.discountLimit }}%) </span>
            </td>
            <td style="width: 20%;">
              <cleave style="height:2.142rem" :disabled="!(saleQuoteData.listingPrice > 0)" v-model="saleQuoteData.discountRate" class="form-control" :raw="true" :options="options.percent" />
            </td>

            <td style="width: 40%;">
              <b-button variant="flat-success" @click="rateCalc" size="sm" class="btn-icon">
                <feather-icon icon="RefreshCcwIcon" />
              </b-button>
            </td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Vehicle Selling Price</td>
            <td scope="col" tyle="width: 80%;">
              <cleave style="height:2.142rem" v-model="saleQuoteData.sellingPrice" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td style="width: 40%;">
              <b-button variant="flat-success" size="sm" @click="sellingPriceCalc" class="btn-icon">
                <feather-icon icon="RefreshCcwIcon" />
              </b-button>
            </td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Less Trade-in Allowance</td>
            <td scope="col" colspan="2">
              {{ formatPrice(saleQuoteData.creditIssued ? saleQuoteData.creditIssued : 0, 2) }}
            </td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Customer Trade-in Equity</th>
            <td scope="col" colspan="2">
              <!-- {{ formatPrice(customerTradeEquity - saleQuoteData.deductionCredit - saleQuoteData.lessAdditionalCredit, 2) }} -->
              {{ formatPrice(customerTradeEquity, 2) }}
            </td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Vehicle Price Difference</td>
            <td scope="col" colspan="2">{{ formatPrice(priceDifference) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">G.S.T Rate & Amount on Vehicle</td>
            <td>
              <cleave id="options" style="height:2.142rem" v-model="saleQuoteData.gstRateVehicle" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td scope="col" style="width: 20%;" class="text-dark font-weight-bold">{{ formatPrice(gstAmountOnVehicle) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">P.S.T Rate & Amount on Vehicle</td>
            <td>
              <cleave id="options" style="height:2.142rem" v-model="saleQuoteData.pstRateVehicle" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td scope="col" class="text-dark font-weight-bold">{{ formatPrice(pstAmountOnVehicle) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right font-weight-bold">Total Vehicle Sale Amount</td>
            <td scope="col" colspan="2">{{ formatPrice(totalVehicleSaleAmount) }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <b-row>
      <table class="table border  text-center table-condensed table-sm mt-1">
        <thead>
          <tr>
            <th scope="col" class="text-capitalize">Additional Purchase Options</th>
            <th scope="col" style="width:10%" class="text-capitalize">Option Price</th>
            <th scope="col" style="width:10%" class="text-capitalize">G.S.T Rate</th>
            <th scope="col" class="text-capitalize">G.S.T Amount</th>
            <th scope="col" style="width:10%" class="text-capitalize">P.S.T Rate</th>
            <th scope="col" class="text-capitalize">P.S.T Amount</th>
            <th scope="col" class="text-capitalize">Option Sub-Total</th>
            <th scope="col" class="text-capitalize">#</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <b-form-input id="options" placeholder="Additional Sale Options" size="sm" v-model="priceCalculationTemp.pcOptions" type="text" />
            </td>
            <td>
              <cleave id="pcPrice" placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPrice" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td>
              <cleave id="pcPrice" placeholder="G.S.T Rate" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcGst" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td>
              <cleave id="pcGstAmount" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcGstAmount" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td>
              <cleave id="pcpst" placeholder="P.S.T  Rate" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPst" class="form-control" :raw="true" :options="options.percent" />
            </td>
            <td>
              <cleave id="pcPstAmount" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcPstAmount" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td>
              <cleave id="pcSubTotal" disabled placeholder="Option Price" style="height:2.142rem; font-size: 0.857rem;" v-model="priceCalculationTemp.pcSubTotal" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="priceCalculationTemp.pcOptions == null || priceCalculationTemp.pcOptions == '' || priceCalculationTemp.pcPrice == null || priceCalculationTemp.pcPrice == ''" @click="priceCalculation()" class="btn-icon" variant="primary">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>

          <tr style="text-align:center" v-for="(item, index) in saleQuoteData.priceCalculationValues" :key="index">
            <td style="text-align:left">{{ item.pcOptions }}</td>
            <td>{{ formatPrice(item.pcPrice) }}</td>
            <td>{{ formatPrice(item.pcGst) }} %</td>
            <td>{{ formatPrice(item.pcGstAmount) }}</td>

            <td>{{ formatPrice(item.pcPst) }} %</td>
            <td>{{ formatPrice(item.pcPstAmount) }}</td>
            <td>{{ formatPrice(item.pcSubTotal) }}</td>

            <td class="text-center"><feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeAdditionalItem(index)" size="16" class="cursor-pointer mx-1" /></td>
          </tr>
          <tr>
            <td class="bg-secondary" colspan="8"></td>
          </tr>

          <tr>
            <th colspan="6" class="text-right">Additional Options Price</th>
            <td>{{ formatPrice(additionaloptionsPrice) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Deduction by Trade-in credit</td>
            <td>
              <cleave
                id="options"
                :disabled="!(saleQuoteData.creditIssued > saleQuoteData.sellingPrice && additionaloptionsPrice > 0)"
                placeholder="0.00"
                style="height:2.142rem; font-size: 0.857rem; text-align: center;"
                v-model="saleQuoteData.deductionCredit"
                class="form-control"
                :raw="true"
                :options="options.number"
              />
            </td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Sub total Additional Options</td>
            <td>{{ formatPrice(additionaloptionsPrice - (saleQuoteData.deductionCredit ? saleQuoteData.deductionCredit : 0)) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">G.S.T. on Additional Options</td>
            <td>{{ formatPrice(gstAdditionalOptions) }}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="6" class="text-right">P.S.T on Additional Options</td>
            <td>{{ formatPrice(pstAdditionalOptions) }}</td>
            <td></td>
          </tr>

          <tr>
            <th colspan="6" class="text-right">Total Additional Options</th>
            <td>{{ formatPrice(totalAdditionalOptions) }}</td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Plus Total Lien Payout on Trade-ins</td>
            <td>{{ formatPrice(sumBeforeTax) }}</td>

            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Total Sale Contract Amount</td>
            <td>{{ formatPrice(contractAmount) }}</td>

            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Less Deposit Payable by Customer</td>
            <td>
              <cleave id="options" placeholder="0.00" style="height:2.142rem; font-size: 0.857rem; text-align: center;" :disabled="saleBalanceDue < 0 || sumBeforeTax < 0" v-model="saleQuoteData.lessDepositPayable" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Less additional Trade-in credit as payment</td>
            <td>
              <cleave
                id="options"
                placeholder="0.00"
                style="height:2.142rem; font-size: 0.857rem; text-align: center;"
                :disabled="!(saleQuoteData.creditIssued > saleQuoteData.sellingPrice && sumBeforeTax > 0)"
                v-model="saleQuoteData.lessAdditionalCredit"
                class="form-control"
                :raw="true"
                :options="options.number"
              />
            </td>
            <td></td>
          </tr>

          <tr>
            <td colspan="6" class="text-right">Balance Due on Delivery</td>
            <td v-if="saleBalanceDue > 0">{{ formatPrice(saleBalanceDue) }}</td>
            <td v-else>0.00</td>
            <td></td>
          </tr>

          <tr v-show="saleBalanceDue < 0 || sumBeforeTax > 0">
            <td colspan="6" class="text-right text-danger">Payable to Customer</td>
            <td class="text-danger">
              <!-- {{ formatPrice(customerTradeEquity - Number(saleQuoteData.balanceAfter)) }} -->
              {{ formatPrice(payableToCustomer) }}
            </td>

            <!-- <td class="text-danger" v-else> -->
            <!-- {{ formatPrice((saleBalanceDue + Number(saleQuoteData.balanceAfter)) * -1) }} -->
            <!-- {{ formatPrice(payableToCustomer) }} -->
            <!-- </td> -->
            <td></td>
          </tr>

          <tr v-show="saleBalanceDue < 0 || sumBeforeTax > 0">
            <td colspan="6" class="text-right  ">Pay as Trade-in Credit</td>
            <td class="text-center ">
              <cleave
                id="options"
                @input="
                  if (sumBeforeTax > 0) {
                    if (saleQuoteData.balanceAfter > customerTradeEquity) {
                      saleQuoteData.balanceAfter = customerTradeEquity;
                    }
                  } else {
                    if (saleQuoteData.balanceAfter > saleBalanceDue * -1) {
                      saleQuoteData.balanceAfter = saleBalanceDue;
                    }
                  }
                "
                style="height:2.142rem; font-size: 0.857rem; text-align: center;"
                v-model="saleQuoteData.balanceAfter"
                class="form-control"
                :raw="true"
                :options="options.number"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BButton } from 'bootstrap-vue';
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,

    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    saleQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      payableToCustomerValue: 0,
      dropdownLoading: false,
      discountRateVal: 0,
      userData: JSON.parse(localStorage.getItem('userData')),

      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'saleQuoteData.listingPrice': {
      handler: function(val, before) {
        if (this.saleQuoteData.listingPrice != 0) {
        } else {
        }
      },
    },

    'saleQuoteData.deductionCredit': {
      handler: function(val, before) {
        var tempCustomerTrade = Number(this.saleQuoteData.creditIssued) - Number(this.saleQuoteData.sellingPrice) - Number(this.saleQuoteData.lessAdditionalCredit);

        if (tempCustomerTrade > 0) {
          if (Number(this.saleQuoteData.deductionCredit) > Number(tempCustomerTrade)) {
            this.saleQuoteData.deductionCredit = Number(tempCustomerTrade);
          }

          if (Number(this.saleQuoteData.deductionCredit) > Number(this.additionaloptionsPrice)) {
            this.saleQuoteData.deductionCredit = Number(this.additionaloptionsPrice);
          }
          this.saleQuoteData.optionsSubTotal = this.additionaloptionsPrice - this.saleQuoteData.deductionCredit;
        }
      },
    },

    'saleQuoteData.creditIssued': {
      handler: function(val, before) {
        if (val <= 0) {
          this.saleQuoteData.deductionCredit = null;
          this.saleQuoteData.lessAdditionalCredit = null;
        } else {
          this.saleQuoteData.lessTradeAllowance = this.saleQuoteData.creditIssued;
        }
      },
    },

    'saleQuoteData.lessAdditionalCredit': {
      handler: function(val, before) {
        // this.saleQuoteData.balanceAfter = 0;   //ORHAN 1

        var tempCustomerTrade = Number(this.saleQuoteData.creditIssued) - Number(this.saleQuoteData.sellingPrice) - Number(this.saleQuoteData.deductionCredit);

        var total = 0;

        if (this.sumBeforeTax > 0) {
          //VERGİ VARSA

          total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.saleQuoteData.lessDepositPayable) + Number(this.totalVehicleSaleAmount));
        } else {
          //VERGİ YOKSA

          if (this.saleQuoteData.creditIssued < this.saleQuoteData.sellingPrice) {
            total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions);
          } else {
            total = Number(Number(this.saleQuoteData.sellingPrice) - Number(this.saleQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.saleQuoteData.deductionCredit));
          }
        }

        if (tempCustomerTrade > 0) {
          if (Number(this.saleQuoteData.lessAdditionalCredit) > total) {
            this.saleQuoteData.lessAdditionalCredit = total;
          }
        }
      },
    },

    'saleQuoteData.sellingPrice': {
      handler: function(val, before) {
        // this.saleQuoteData.balanceAfter = 0; // ORHAN 2

        if (this.saleQuoteData.listingPrice != 0 && this.saleQuoteData.listingPrice != null) {
          if (val > this.saleQuoteData.listingPrice) {
            this.saleQuoteData.sellingPrice = this.saleQuoteData.listingPrice;
          }
          this.discountRateVal = this.saleQuoteData.listingPrice - this.saleQuoteData.sellingPrice;
        }
      },
    },

    'saleQuoteData.lessDepositPayable': {
      //505
      handler: function(val, before) {
        var total = 0;

        if (this.sumBeforeTax > 0) {
          //VERGİ VARSA

          total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.saleQuoteData.lessAdditionalCredit) + Number(this.totalVehicleSaleAmount));
        } else {
          //VERGİ YOKSA

          if (this.saleQuoteData.creditIssued < this.saleQuoteData.sellingPrice) {
            total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions);
          } else {
            total = Number(Number(this.saleQuoteData.sellingPrice) - Number(this.saleQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.saleQuoteData.deductionCredit));
          }
        }

        if (this.saleQuoteData.lessDepositPayable != null || this.saleQuoteData.lessDepositPayable != '') {
          if (total - Number(this.saleQuoteData.lessDepositPayable) < 0) {
            this.saleQuoteData.lessDepositPayable = total;
          }
        }
      },
    },

    'saleQuoteData.discountRate': {
      handler: function(val, before) {
        if (this.userData.discountLimit != null && this.userData.discountLimit != '') {
          if (val > this.userData.discountLimit) {
            this.saleQuoteData.discountRate = 10;
          }
        }

        if (this.saleQuoteData.discountRate == null || this.saleQuoteData.discountRate == '') {
          this.discountRateVal = 0;
        } else {
          this.discountRateVal = this.saleQuoteData.listingPrice * (this.saleQuoteData.discountRate / 100);
        }
      },
    },

    'priceCalculationTemp.pcPrice': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPrice != null || this.priceCalculationTemp.pcGst != '' || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcGstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst;

          this.priceCalculationTemp.pcPstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst;

          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcGst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcGst != null || this.priceCalculationTemp.pcGst != '') {
          this.priceCalculationTemp.pcGstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcGst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcPst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPst != null || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcPstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcPst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },
  },

  computed: {
    priceDifference() {
      var tempCustomerTrade = Number(this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit);
      if (tempCustomerTrade <= 0) {
        this.saleQuoteData.priceDifference = Number((this.saleQuoteData.sellingPrice ?? 0) - (this.saleQuoteData.creditIssued ?? 0));
        return Number((this.saleQuoteData.sellingPrice ?? 0) - (this.saleQuoteData.creditIssued ?? 0));
      } else {
        this.saleQuoteData.priceDifference = 0;
        return 0;
      }
    },

    payableToCustomer() {
      if (this.sumBeforeTax > 0) {
        this.payableToCustomerValue = this.customerTradeEquity - Number(this.saleQuoteData.balanceAfter);
      } else {
        this.payableToCustomerValue = (this.saleBalanceDue + Number(this.saleQuoteData.balanceAfter)) * -1;
      }
      this.saleQuoteData.payableToCustomerValue = this.payableToCustomerValue;

      return this.payableToCustomerValue;
    },

    sumBeforeTax() {
      // this.saleQuoteData.lessDepositPayable = 0;
      // this.saleQuoteData.lessAdditionalCredit = 0;

      var total = 0;

      this.saleQuoteData.trades.forEach((element) => {
        total += Number(element.beforeTax);
      });

      this.saleQuoteData.winTrades.forEach((element) => {
        total += Number(element.beforeTax);
      });
      this.saleQuoteData.lienPayoutTrades = total;

      return Number(total);
    },

    saleBalanceDue() {
      var total = 0;
      if (this.sumBeforeTax > 0) {
        //VERGİ VARSA

        total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.saleQuoteData.lessAdditionalCredit) - Number(this.saleQuoteData.lessDepositPayable) + Number(this.totalVehicleSaleAmount));
      } else {
        //VERGİ YOKSA

        if (this.saleQuoteData.creditIssued < this.saleQuoteData.sellingPrice) {
          total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions - Number(this.saleQuoteData.lessDepositPayable));
        } else {
          total = Number(Number(this.saleQuoteData.sellingPrice) - Number(this.saleQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.saleQuoteData.deductionCredit));
        }
      }
      if (total > 0) {
        this.saleQuoteData.dueOnDelivery = total;
      } else {
        this.saleQuoteData.dueOnDelivery = 0;
      }

      this.saleQuoteData.paymentBalanceTotal = this.saleQuoteData.balanceDue;

      return total;
    },

    customerTradeEquity() {
      if (this.saleQuoteData.creditIssued > this.saleQuoteData.sellingPrice) {
        this.saleQuoteData.customerTradeEquity = this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit - this.saleQuoteData.lessAdditionalCredit;
        return this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit - this.saleQuoteData.lessAdditionalCredit;
      } else {
        this.saleQuoteData.deductionCredit = null;
        this.saleQuoteData.lessAdditionalCredit = null;
        this.saleQuoteData.customerTradeEquity = 0;

        return 0;
      }
    },

    gstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        this.saleQuoteData.gstAmount = 0;
        return 0;
      } else if (this.saleQuoteData.gstRateVehicle == null || this.saleQuoteData.gstRateVehicle == '') {
        this.saleQuoteData.gstAmount = 0;

        return 0;
      } else {
        this.saleQuoteData.gstAmount = Number((this.priceDifference / 100) * this.saleQuoteData.gstRateVehicle);

        return Number((this.priceDifference / 100) * this.saleQuoteData.gstRateVehicle);
      }
    },

    pstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        this.saleQuoteData.pstAmount = 0;

        return 0;
      } else if (this.saleQuoteData.pstRateVehicle == null || this.saleQuoteData.pstRateVehicle == '') {
        this.saleQuoteData.pstAmount = 0;

        return 0;
      } else {
        this.saleQuoteData.pstAmount = Number((this.priceDifference / 100) * this.saleQuoteData.pstRateVehicle);

        return Number((this.priceDifference / 100) * this.saleQuoteData.pstRateVehicle);
      }
    },

    totalVehicleSaleAmount() {
      this.saleQuoteData.totalSaleAmount = Number(this.priceDifference + this.gstAmountOnVehicle + this.pstAmountOnVehicle);
      return Number(this.priceDifference + this.gstAmountOnVehicle + this.pstAmountOnVehicle);
    },

    totalAdditionalOptions() {
      this.saleQuoteData.optionAmountTotal = Number(this.additionaloptionsPrice - (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) + this.gstAdditionalOptions + this.pstAdditionalOptions);
      return Number(this.additionaloptionsPrice - (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) + this.gstAdditionalOptions + this.pstAdditionalOptions);
    },

    additionaloptionsPrice() {
      var total = 0;
      if (this.saleQuoteData.priceCalculationValues.length == 0) {
        this.saleQuoteData.deductionCredit = 0;
      }
      this.saleQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcPrice);
      });
      return total;
    },

    gstAdditionalOptions() {
      if (this.saleQuoteData.creditIssued > this.saleQuoteData.sellingPrice) {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcGst) < Number(rate)) {
            rate = element.pcGst;
          }
        });

        total = ((this.additionaloptionsPrice - this.saleQuoteData.deductionCredit) * rate) / 100;
        this.saleQuoteData.optionsGst = total;
        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcGst) < Number(rate)) {
            rate = element.pcGst;
          }
        });

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });
        this.saleQuoteData.optionsGst = total;

        return total;
      }
    },

    pstAdditionalOptions() {
      if (this.saleQuoteData.creditIssued > this.saleQuoteData.sellingPrice) {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcPst) < Number(rate)) {
            rate = element.pcPst;
          }
        });

        total = ((this.additionaloptionsPrice - this.saleQuoteData.deductionCredit) * rate) / 100;
        this.saleQuoteData.optionsPst = total;

        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcPst) < Number(rate)) {
            rate = element.pcPst;
          }
        });

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });
        this.saleQuoteData.optionsPst = total;

        return total;
      }
    },

    contractAmount() {
      this.saleQuoteData.totalContractAmount =
        Number(this.additionaloptionsPrice) +
        Number(this.priceDifference) +
        Number(this.gstAmountOnVehicle) +
        Number(this.pstAmountOnVehicle) +
        Number(this.sumBeforeTax) -
        (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) +
        Number(this.gstAdditionalOptions) +
        Number(this.pstAdditionalOptions);

      return (
        Number(this.additionaloptionsPrice) +
        Number(this.priceDifference) +
        Number(this.gstAmountOnVehicle) +
        Number(this.pstAmountOnVehicle) +
        Number(this.sumBeforeTax) -
        (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) +
        Number(this.gstAdditionalOptions) +
        Number(this.pstAdditionalOptions)
      );
    },
  },

  methods: {
    priceCalculation() {
      this.saleQuoteData.priceCalculationValues.push(this.priceCalculationTemp);
      this.priceCalculationTemp = {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      };
    },

    rateCalc() {
      this.saleQuoteData.sellingPrice = this.saleQuoteData.listingPrice - (this.saleQuoteData.listingPrice * this.saleQuoteData.discountRate) / 100;
    },

    sellingPriceCalc() {
      this.saleQuoteData.discountRate = 100 - (100 * this.saleQuoteData.sellingPrice) / this.saleQuoteData.listingPrice;
    },

    removeAdditionalItem(e) {
      this.$delete(this.saleQuoteData.priceCalculationValues, e);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>
